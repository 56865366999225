<template>
<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="titlePage titlePage_subtitle">Terms & Conditions</h1>
    </div>
    <div class="col-12">
      <h3 class="text_center">Jolly Jane Pty Ltd T/A Starline Security - Terms & Conditions of Trade</h3>
      <div class="mb-3">
        <div class="d-inline-flex">
          <strong><span class="mr-3-plus-half">1.</span><span>Definitions</span></strong>
        </div>
        <div class="d-inline-flex"><span class="mr-3">1.1</span><span>“Starline” shall mean Jolly Jane Pty Ltd T/A Starline Security its successors and assigns or any person acting onbehalf of and with the authority of Jolly Jane Pty Ltd T/A Starline Security.</span></div>
        <div class="d-inline-flex"><span class="mr-3">1.2</span>“Client” shall mean the Client (or any person acting on behalf of and with the authority of the Client) as describedon any quotation, work authorisation or other form as provided by Starline to the Client.</div>
        <div class="d-inline-flex"><span class="mr-3">1.3</span>“Guarantor” means that person (or persons), or entity, who agrees to be liable for the debts of the Client on aprincipal debtor basis.</div>
        <div class="d-inline-flex"><span class="mr-3">1.4</span>“Goods” shall mean Goods supplied by Starline to the Client (and where the context so permits shall include anysupply of Services as hereinafter defined) and are as described on the invoices, quotation, work authorisation orany other forms as provided by Starline to the Client.</div>
        <div class="d-inline-flex"><span class="mr-3">1.5</span>“Services” shall mean all Services supplied by Starline to the Client and includes any advice or recommendations(and where the context so permits shall include any supply of Goods as defined above).</div>
        <div class="d-inline-flex"><span class="mr-3">1.6</span>“Price” shall mean the Price payable for the Goods as agreed between Starline and the Client in accordance withclause 4 of this contract.</div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">2.</span>
            <span>The Commonwealth Trade Practices Act 1974 (“TPA”) and Fair Trading Acts (“FTA”)</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">2.1</span>
          <span>
            Nothing in this agreement is intended to have the effect of contracting out of any applicable provisions of the TPAor the FTA in each of the States and Territories of Australia, except to the extent permitted by those Acts whereapplicable.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">3.</span>
            <span>Acceptance</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">3.1</span>
          <span>
            Any instructions received by Starline from the Client for the supply of Goods and/or the Client’s acceptance ofGoods supplied by Starline shall constitute acceptance of the terms and conditions contained herein.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">3.2</span>
          <span>
            Where more than one Client has entered into this agreement, the Clients shall be jointly and severally liable for all payments of the Price.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">3.3</span>
          <span>
            Upon acceptance of these terms and conditions by the Client the terms and conditions are binding and can only be amended with the written consent of Starline.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">3.4</span>
          <span>
            The Client shall give Starline not less than fourteen (14) days prior written notice of any proposed change of ownership of the Client or any change in the Client’s name and/or any other change in the Client’s details (including but not limited to, changes in the Client’s address, facsimile number, or business practice). The Client shall be liable for any loss incurred by Starline as a result of the Client’s failure to comply with this clause.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">3.5</span>
          <span>
            Goods are supplied by Starline only on the terms and conditions of trade herein to the exclusion of anything to the contrary in the terms of the Client’s order notwithstanding that any such order is placed on terms that purport to override these terms and conditions of trade.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">4.</span>
            <span>Price and Payment</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">4.1</span>
          <span>
            At Starline’s sole discretion the Price shall be either: <br />(a) as indicated on invoices provided by Starline to the Client in respect of Goods supplied; or <br />(b) Starline’s quoted Price (subject to clause 4.2) which shall be binding upon Starline provided that the Client shall accept Starline’s quotation in writing within thirty (30) days.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">4.2</span>
          <span>
            Starline reserves the right to change the Price in the event of a variation to Starline’s quotation. Any variation from the plan of scheduled works or specifications (including, but not limited to, any variation due to unforeseen circumstances (including, but not limited to, the door jams being damaged by termites, or due to incorrect measurements being supplied), or as a result of increases to Starline in the cost of materials and labour) will be charged for on the basis of Starline’s quotation and will be shown as variations on the invoice. Payment for all variations must be made in full at their time of completion.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">4.3</span>
          <span>
            At Starline’s sole discretion a non-refundable deposit may be required.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">4.4</span>
          <span>
            At Starline’s sole discretion payment shall be due on delivery of the Goods.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">4.5</span>
          <span>
            Time for payment for the Goods shall be of the essence and will be stated on the invoice or any other forms. If notime is stated then payment shall be due seven (7) days following the date of the invoice.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">4.6</span>
          <span>
            Payment will be made by cash, or by cheque, or by bank cheque, or by credit card (plus a surcharge of up to onepercent (1%) of the Price), or by direct credit, or by any other method as agreed to between the Client and Starline.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">4.7</span>
          <span>
            GST and other taxes and duties that may be applicable shall be added to the Price except when they are expresslyincluded in the Price.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">5.</span>
            <span>Delivery of Goods</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">5.1</span>
          <span>
            At Starline’s sole discretion delivery of the Goods shall take place when the Client takes possession of the Goodsat the Client’s nominated address (in the event that the Goods are delivered by Starline or Starline’s nominatedcarrier).
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">5.2</span>
          <span>
            At Starline’s sole discretion the costs of delivery are included in the Price.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">5.3</span>
          <span>
            The Client shall make all arrangements necessary to take delivery of the Goods whenever they are tendered fordelivery. In the event that the Client is unable to take delivery of the Goods as arranged then Starline shall beentitled to charge a reasonable fee for redelivery.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">5.4</span>
          <span>
            Delivery of the Goods to a third party nominated by the Client is deemed to be delivery to the Client for thepurposes of this agreement.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">5.5</span>
          <span>
            Starline may deliver the Goods by separate instalments. Each separate instalment shall be invoiced and paid inaccordance with the provisions in these terms and conditions.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">5.6</span>
          <span>
            The failure of Starline to deliver shall not entitle either party to treat this contract as repudiated.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">5.7</span>
          <span>
            Starline shall not be liable for any loss or damage whatsoever due to failure by Starline to deliver the Goods (or anyof them) promptly or at all, where due to circumstances beyond the control of Starline.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">6.</span>
            <span>Risk</span>
          </strong>
        </div><br/>

        <div class="d-inline-flex">
          <span class="mr-3">6.1</span>
          <span>
            If Starline retains ownership of the Goods nonetheless, all risk for the Goods passes to the Client on delivery.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">6.2</span>
          <span>
            If any of the Goods are damaged or destroyed following delivery but prior to ownership passing to the Client,Starline is entitled to receive all insurance proceeds payable for the Goods. The production of these terms andconditions by Starline is sufficient evidence of Starline’s rights to receive the insurance proceeds without the needfor any person dealing with Starline to make further enquiries.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">6.3</span>
          <span>
            Where the Client expressly requests Starline to leave Goods outside Starline’s premises for collection or to deliverthe Goods to an unattended location then such Goods shall be left at the Client’s sole risk and it shall be theClient’s responsibility to ensure the Goods are insured adequately or at all.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">7</span>
            <span>Access</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">7.1</span>
          <span>
            The Client shall ensure that Starline has clear and free access to the work site at all times to enable them toundertake the works. Starline shall not be liable for any loss or damage to the site (including, without limitation,damage to pathways, driveways and concreted or paved or grassed areas) unless due to the negligence ofStarline.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">8</span>
            <span>Title</span>
          </strong>
        </div><br/>
        <div class="d-inline-flex">
          <span class="mr-3">8.1</span>
          <span>
            Starline and the Client agree that ownership of the Goods shall not pass until: <br />(a) the Client has paid Starline all amounts owing for the particular Goods; and <br />(b) the Client has met all other obligations due by the Client to Starline in respect of all contracts between Starline and the Client.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">8.2</span>
          <span>
            Receipt by Starline of any form of payment other than cash shall not be deemed to be payment until that form of payment has been honoured, cleared or recognised and until then Starline’s ownership or rights in respect of the Goods shall continue.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">8.3</span>
          <span>
            It is further agreed that: <br />(a) where practicable the Goods shall be kept separate and identifiable until Starline shall have received payment and all other obligations of the Client are met; and <br />(b) until such time as ownership of the Goods shall pass from Starline to the Client Starline may give notice in writing to the Client to return the Goods or any of them to Starline. Upon such notice the rights of the Client to obtain ownership or any other interest in the Goods shall cease; and <br />(c) Starline shall have the right of stopping the Goods in transit whether or not delivery has been made; and <br />(d) if the Client fails to return the Goods to Starline then Starline or Starline’s agent may enter upon and into land and premises owned, occupied or used by the Client, or any premises as the invitee of the Client, where the Goods are situated and take possession of the Goods; and <br />(e) the Client is only a bailee of the Goods and until such time as Starline has received payment in full for the Goods then the Client shall hold any proceeds from the sale or disposal of the Goods, up to and including the amount the Client owes to Starline for the Goods, on trust for Starline; and <br />(f) the Client shall not deal with the money of Starline in any way which may be adverse to Starline; and <br />(g) the Client shall not charge the Goods in any way nor grant nor otherwise give any interest in the Goods while they remain the property of Starline; and <br />(h) Starline can issue proceedings to recover the Price of the Goods sold notwithstanding that ownership of the Goods may not have passed to the Client; and <br />(i) until such time that ownership in the Goods passes to the Client, if the Goods are converted into other products, the parties agree that Starline will be the owner of the end products.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">9.</span>
            <span>Defects</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">9.1</span>
          <span>
            The Client shall inspect the Goods on delivery and shall within seven (7) days of delivery (time being of theessence) notify Starline of any alleged defect, shortage in quantity, damage or failure to comply with the descriptionor quote. The Client shall afford Starline an opportunity to inspect the Goods within a reasonable time followingdelivery if the Client believes the Goods are defective in any way. If the Client shall fail to comply with theseprovisions the Goods shall be presumed to be free from any defect or damage. For defective Goods, which Starlinehas agreed in writing that the Client is entitled to reject, Starline’s liability is limited to either (at Starline’s discretion)replacing the Goods or repairing the Goods except where the Client has acquired Goods as a consumer within themeaning of the Trade Practices Act 1974 (CWlth) or the Fair Trading Acts of the relevant state or territories ofAustralia, and is therefore also entitled to, at the consumer’s discretion either a refund of the purchase price of theGoods, or repair of the Goods, or replacement of the Goods.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">9.2</span>
          <span>
            Goods will not be accepted for return other than in accordance with 9.1 above.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">10</span>
            <span>Warranty</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">10.1</span>
          <span>
            Subject to the conditions of warranty set out in clause 10.2 Starline warrants that if any defect in any workmanshipof Starline becomes apparent and is reported to Starline within twelve (12) months of the date of delivery (timebeing of the essence) then Starline will either (at Starline’s sole discretion) replace or remedy the workmanship.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">10.2</span>
          <span>
            The conditions applicable to the warranty given by clause 10.1 are:<br />(a) the warranty shall not cover any defect or damage which may be caused or partly caused by or arise through:<br />&nbsp;&nbsp;&nbsp;&nbsp;(i) failure on the part of the Client to properly maintain any Goods; or<br />&nbsp;&nbsp;&nbsp;&nbsp;(ii) failure on the part of the Client to follow any instructions or guidelines provided by Starline; or<br />&nbsp;&nbsp;&nbsp;&nbsp;(iii)any use of any Goods otherwise than for any application specified on a quote or order form; or<br />&nbsp;&nbsp;&nbsp;&nbsp;(iv) the continued use of any Goods after any defect becomes apparent or would have become apparent to areasonably prudent operator or user; or<br />&nbsp;&nbsp;&nbsp;&nbsp;(v) fair wear and tear, any accident or act of God.<br />(b) the warranty shall cease and Starline shall thereafter in no circumstances be liable under the terms of thewarranty if the workmanship is repaired, altered or overhauled without Starline’s consent.<br />(c) in respect of all claims Starline shall not be liable to compensate the Client for any delay in either replacing orremedying the workmanship or in properly assessing the Client’s claim.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">10.3</span>
          <span>
            For Goods not manufactured by Starline, the warranty shall be the current warranty provided by the manufacturerof the Goods. Starline shall not be bound by nor be responsible for any term, condition, representation or warrantyother than that which is given by the manufacturer of the Goods.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">10.4</span>
          <span>
            The conditions applicable to the warranty given on Goods supplied by Starline are contained on the “WarrantyCard” that will be supplied with the Goods.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">11.</span>
            <span>Intellectual Property</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">11.1</span>
          <span>
            Where Starline has designed, drawn or written Goods for the Client, then the copyright in those designs anddrawings and documents shall remain vested in Starline, and shall only be used by the Client at Starline’sdiscretion.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">11.2</span>
          <span>
            The Client warrants that all designs or instructions to Starline will not cause Starline to infringe any patent,registered design or trademark in the execution of the Client’s order and the Client agrees to indemnify Starlineagainst any action taken by a third party against Starline in respect of any such infringement.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">11.3</span>
          <span>
            The Client hereby authorises Starline to utilise images of the Goods designed or drawn by Starline in advertising,marketing, or competition material by Starline.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">12.</span>
            <span>Default and Consequences of Default</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">12.1</span>
          <span>
            Interest on overdue invoices shall accrue daily from the date when payment becomes due, until the date ofpayment, at a rate of two and a half percent (2.5%) per calendar month (and at Starline’s sole discretion suchinterest shall compound monthly at such a rate) after as well as before any judgment.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">12.2</span>
          <span>
            In the event that the Client’s payment is dishonoured for any reason the Client shall be liable for any dishonour feesincurred by Starline.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">12.3</span>
          <span>
            If the Client defaults in payment of any invoice when due, the Client shall indemnify Starline from and against allcosts and disbursements incurred by Starline in pursuing the debt including legal costs on a solicitor and own clientbasis and Starline’s collection agency costs.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">12.4</span>
          <span>
            Without prejudice to any other remedies Starline may have, if at any time the Client is in breach of any obligation(including those relating to payment) Starline may suspend or terminate the supply of Goods to the Client and anyof its other obligations under the terms and conditions. Starline will not be liable to the Client for any loss ordamage the Client suffers because Starline has exercised its rights under this clause.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">12.5</span>
          <span>
            If any account remains overdue after thirty (30) days then an amount of the greater of twenty dollars ($20.00) or tenpercent (10%) of the amount overdue (up to a maximum of two hundred dollars ($200.00)) shall be levied foradministration fees which sum shall become immediately due and payable.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">12.6</span>
          <span>
            Without prejudice to Starline’s other remedies at law Starline shall be entitled to cancel all or any part of any order of the Client which remains unfulfilled and all amounts owing to Starline shall, whether or not due for payment, become immediately payable in the event that: <br />(a) any money payable to Starline becomes overdue, or in Starline’s opinion the Client will be unable to meet its payments as they fall due; or <br />(b) the Client becomes insolvent, convenes a meeting with its creditors or proposes or enters into an arrangement with creditors, or makes an assignment for the benefit of its creditors; or <br />(c) a receiver, manager, liquidator (provisional or otherwise) or similar person is appointed in respect of the Client or any asset of the Client.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">13.</span>
            <span>Security and Charge</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">13.1</span>
          <span>
            Despite anything to the contrary contained herein or any other rights which Starline may have howsoever: <br />(a) where the Client and/or the Guarantor (if any) is the owner of land, realty or any other asset capable of being charged, both the Client and/or the Guarantor agree to mortgage and/or charge all of their joint and/or several interest in the said land, realty or any other asset to Starline or Starline’s nominee to secure all amounts and other monetary obligations payable under these terms and conditions. The Client and/or the Guarantor acknowledge and agree that Starline (or Starline’s nominee) shall be entitled to lodge where appropriate a caveat, which caveat shall be withdrawn once all payments and other monetary obligations payable hereunder have been met. <br />(b) should Starline elect to proceed in any manner in accordance with this clause and/or its sub-clauses, the Client and/or Guarantor shall indemnify Starline from and against all Starline’s costs and disbursements including legal costs on a solicitor and own client basis. <br />(c) the Client and/or the Guarantor (if any) agree to irrevocably nominate constitute and appoint Starline or Starline’s nominee as the Client’s and/or Guarantor’s true and lawful attorney to perform all necessary acts to give effect to the provisions of this clause 13.1.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">14.</span>
            <span>Dispute Resolution</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">14.1</span>
          <span>
            If a dispute arises between the parties to this contract then either party shall send to the other party a notice of dispute in writing adequately identifying and providing details of the dispute. Within fourteen (14) days after service of a notice of dispute, the parties shall confer at least once, to attempt to resolve the dispute. At any such conference each party shall be represented by a person having authority to agree to a resolution of the dispute. In the event that the dispute cannot be so resolved either party may by further notice in writing delivered by hand or sent by certified mail to the other party refer such dispute to arbitration. Any arbitration shall be: <br />(a) referred to a single arbitrator to be nominated by the President of the Institute of Arbitrators Australia; and <br />(b) conducted in accordance with the Institute of Arbitrators Australia Rules for the Conduct of Commercial Arbitration
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">15.</span>
            <span>Compliance with Laws</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">15.1</span>
          <span>
            The Client and Starline shall comply with the provisions of all statutes, regulations and bylaws of government, localand other public authorities that may be applicable to the works.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">15.2</span>
          <span>
            The Client shall obtain (at the expense of the Client) all licenses and approvals that may be required for the works.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">15.3</span>
          <span>
            The Client agrees that the site will comply with any occupational health and safety laws relating tobuilding/construction sites and any other relevant safety standards or legislation.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">16.</span>
            <span>Cancellation</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">16.1</span>
          <span>
            Starline may cancel any contract to which these terms and conditions apply or cancel delivery of Goods at any timebefore the Goods are delivered by giving written notice to the Client. On giving such notice Starline shall repay tothe Client any sums paid in respect of the Price. Starline shall not be liable for any loss or damage whatsoeverarising from such cancellation.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">16.2</span>
          <span>
            In the event that the Client cancels delivery of Goods the Client shall be liable for any loss incurred by Starline(including, but not limited to, any loss of profits) up to the time of cancellation.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">16.3</span>
          <span>
            Cancellation of orders for Goods made to the Client’s specifications or non-stocklist items will definitely not beaccepted, once production has commenced.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">17.</span>
            <span>Privacy Act 1988</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">17.1</span>
          <span>
            The Client and/or the Guarantor/s agree for Starline to obtain from a credit reporting agency a credit reportcontaining personal credit information about the Client and Guarantor/s in relation to credit provided by Starline.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">17.2</span>
          <span>
            The Client and/or the Guarantor/s agree that Starline may exchange information about the Client and the Guarantor/s with those credit providers either named as trade referees by the Client or named in a consumer credit report issued by a credit reporting agency for the following purposes: <br />(a) to assess an application by Client; and/or <br />(b) to notify other credit providers of a default by the Client; and/or <br />(c) to exchange information with other credit providers as to the status of this credit account, where the Client is in default with other credit providers; and/or <br />(d) to assess the credit worthiness of Client and/or Guarantor/s
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">17.3</span>
          <span>
            The Client consents to Starline being given a consumer credit report to collect overdue payment on commercialcredit (Section 18K(1)(h) Privacy Act 1988).
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">17.4</span>
          <span>
            The Client agrees that personal credit information provided may be used and retained by Starline for the following purposes and for other purposes as shall be agreed between the Client and Starline or required by law from time to time: <br/>(a) provision of Goods; and/or <br/>(b) marketing of Goods by Starline, its agents or distributors in relation to the Goods; and/or <br/>(c) analysing, verifying and/or checking the Client’s credit, payment and/or status in relation to provision of Goods; and/or <br/>(d) processing of any payment instructions, direct debit facilities and/or credit facilities requested by Client; and/or <br/>(e) enabling the daily operation of Client’s account and/or the collection of amounts outstanding in the Client’s account in relation to the Goods.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">17.5</span>
          <span>
            Starline may give information about the Client to a credit reporting agency for thefollowing purposes: <br/>(a) to obtain a consumer credit report about the Client; and/or <br/>(b) allow the credit reporting agency to create or maintain a credit information file containing information about the Client.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">18.</span>
            <span>Unpaid Starline’s Rights</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">18.1</span>
          <span>
            Where the Client has left any item with Starline for repair, modification, exchange or for Starline to perform any other Service in relation to the item and Starline has not received or been tendered the whole of the Price, or the payment has been dishonoured, Starline shall have: <br />(a) a lien on the item; <br />(b) the right to retain the item for the Price while Starline is in possession of theitem; <br />(c) a right to sell the item.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">18.2</span>
          <span>
            The lien of Starline shall continue despite the commencement of proceedings, or judgment for the Price havingbeen obtained.
          </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">19.</span>
            <span>Definitions</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">19.1</span>
          <span>
            At Starline’s sole discretion, if there are any disputes or claims for unpaid Goods and/or Services then theprovisions of the Building and Construction Industry Payments Act 2004 may apply.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">19.2</span>
          <span>
            Nothing in this agreement is intended to have the affect of contracting out of any applicable provisions of theBuilding and Construction Industry Payments Act 2004 of Queensland, except to the extent permitted by the Actwhere applicable.
          </span>
        </div>
      </div>

      <div class="mb-5">
        <div class="d-inline-flex">
          <strong>
            <span class="mr-3-plus-half">20.</span>
            <span>Definitions</span>
          </strong>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">20.1</span>
          <span>
            If any provision of these terms and conditions shall be invalid, void, illegal or unenforceable the validity, existence,legality and enforceability of the remaining provisions shall not be affected, prejudiced or impaired.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">20.2</span>
          <span>
            These terms and conditions and any contract to which they apply shall be governed by the laws of Queensland andare subject to the jurisdiction of the courts of Queensland.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">20.3</span>
          <span>
            Starline shall be under no liability whatsoever to the Client for any indirect and/or consequential loss and/orexpense (including loss of profit) suffered by the Client arising out of a breach by Starline of these terms andconditions.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">20.4</span>
          <span>
            In the event of any breach of this contract by Starline the remedies of the Client shall be limited to damages whichunder no circumstances shall exceed the Price of the Goods.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">20.5</span>
          <span>
            The Client shall not be entitled to set off against, or deduct from the Price, any sums owed or claimed to be owed tothe Client by Starline nor to withhold payment of any invoice because part of that invoice is in dispute.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">20.6</span>
          <span>
            Starline may license or sub-contract all or any part of its rights and obligations without the Client’s consent.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">20.7</span>
          <span>
            The Client agrees that Starline may review these terms and conditions at any time. If, following any such review,there is to be any change to these terms and conditions, then that change will take effect from the date on whichStarline notifies the Client of such change.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">20.8</span>
          <span>
            Neither party shall be liable for any default due to any act of God, war, terrorism, strike, lock-out, industrial action,fire, flood, storm or other event beyond the reasonable control of either party.
          </span>
        </div>
        <div class="d-inline-flex">
          <span class="mr-3">20.9</span>
          <span>
            The failure by Starline to enforce any provision of these terms and conditions shall not be treated as a waiver ofthat provision, nor shall it affect Starline’s right to subsequently enforce that provision.
          </span>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
  export default {
    name: "TermsConditions"
  }
</script>

<style scoped>

</style>
